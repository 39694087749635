<script lang="ts" setup>
import type { EntryCollection, EntrySys } from "contentful";
import type {
  TypeDownloadSkeleton,
  TypeTableDownloads,
} from "~/types/contentful";
import { isTypeTheme, isTypeTopic } from "~/types/contentful";

const props = defineProps<{
  fields: TypeTableDownloads<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
}>();

const route = useRoute();

const filterYears = ref<string[]>([]);
const filterProjects = ref<string[]>([]);
const filterThemes = ref<string[]>([]);
const filterContentTypes = ref<string[]>([]);
const input = ref("");
const currentPage = ref(Number(useRoute().query[`page_${props.sys.id}`]) || 1);
const perPage = 18;
const container = ref<ComponentPublicInstance>();

const filterYearsParams = computed(() => {
  return filterYears.value.join(",");
});

const filterProjectsParams = computed(() => {
  return [
    ...filterProjects.value,
    ...(props.fields.project?.sys.id ? [props.fields.project.sys.id] : []),
  ].join(",");
});

const filterThemesParams = computed(() => {
  return [
    ...filterThemes.value,
    ...(props.fields.theme?.sys.id ? [props.fields.theme.sys.id] : []),
  ].join(",");
});

const filterContentTypeParams = computed(() => {
  return filterContentTypes.value.join(",");
});

const { data: allDownloads } = await useLazyFetch<
  EntryCollection<TypeDownloadSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/downloads", {
  query: {
    projectIds: props.fields.project?.sys.id,
    perPage: 1000,
  },
});

const { data: filteredDownloads } = await useLazyFetch<
  EntryCollection<TypeDownloadSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/downloads", {
  query: {
    tags: filterYearsParams,
    themeOrTopicIds: filterThemesParams,
    projectIds: filterProjectsParams,
    companyIds: props.fields.company?.sys.id,
    contentTypes: filterContentTypeParams,
    input,
    page: computed(() => Number(useRoute().query[`page_${props.sys.id}`]) || 1),
    perPage,
  },
});

// Compute pagination total based on whether articles are filtered or not
const paginationTotal = computed(() => filteredDownloads.value?.total ?? 0);

const filteredDownloadsSorted = computed(() => [
  ...new Set(
    [...filteredDownloads.value?.items ?? []].sort(
      (a, b) => Date.parse(b.fields.date) - Date.parse(a.fields.date),
    ),
  ),
]);

const years = computed(() => [
  ...new Set(
    allDownloads.value?.items
      .map((download) => download.metadata.tags.filter(tag => tag.sys.id.includes('year')).map((tag) => tag.sys.id))
      .flat(),
  ),
]);

const projects = computed(() => [
  ...new Map(
    [
      ...new Set(
        allDownloads.value?.items.map((download) => download.fields.project),
      ),
    ]
      .filter((project): project is NonNullable<typeof project> => !!project)
      .map((project) => [project.sys.id, project]),
  ).values(),
]);

const themes = computed(() => [
  ...new Map(
    [
      ...new Set(
        allDownloads.value?.items.map(
          (download) => download.fields.themeOrTopic,
        ),
      ),
    ]
      .filter((theme): theme is NonNullable<typeof theme> => !!theme)
      .map((theme) => [theme.sys.id, theme]),
  ).values(),
]);

const contentTypes = computed(() => [
  ...new Set(
    allDownloads.value?.items.map((download) => download.fields.contentType),
  ),
]);

const filterPanelBlocks = computed(() => [
  [...years.value].sort(),
  [
    ...projects.value,
  ].sort((a, b) =>
    (a.fields.name ?? "").localeCompare(b.fields.name ?? ""),
  ),
  [
    ...themes.value,
  ].sort((a, b) =>
    (a.fields.title ?? "").localeCompare(b.fields.title ?? ""),
  ),
  [...contentTypes.value].sort(),
]);

const filters = computed(() => [
  filterYears.value,
  filterProjects.value,
  filterThemes.value,
  filterContentTypes.value,
]);

const onFilterChange = (checked: boolean, id: string, blockIndex: number) => {
  if (checked) {
    filters.value[blockIndex]?.push(id);
  } else {
    filters.value[blockIndex]?.splice(filterYears.value.indexOf(id), 1);
  }
};

// Watch for changes in search input and reset current page to 1
watch(input, () => (currentPage.value = 1));

// Watch for changes in current page and scroll into view
watch(currentPage, async (page) => {
  container.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  await navigateTo({
    hash: route.hash,
    query: { [`page_${props.sys.id}`]: page },
  });
});
</script>

<template>
  <Container
    ref="container"
    class="table--downloads py-4 md:py-6"
  >
    <div
      v-if="fields.displaySectionTitle"
      class="w-full"
    >
      <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
    </div>
    <div class="mt-4 flex flex-col justify-between md:flex-row">
      <div
        class="download-search mb-3 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:w-[70%] md:max-w-[700px]"
      >
        <NuxtIcon
          class="mx-2"
          name="fairr:search"
          size="24"
        />
        <input
          v-model="input"
          type="text"
          placeholder="Search for..."
        />
      </div>
      <UiFilterPanel
        :titles="['Year', 'Project Type', 'Theme/Topic', 'Content Type']"
        :blocks="filterPanelBlocks"
        scroll-on-open
        @on-filter-change="onFilterChange"
      />
    </div>
    <div
      class="table-container mt-3"
      role="table"
      aria-label="Downloads"
    >
      <div
        class="flex-table header"
        role="rowgroup"
      >
        <div
          class="text-tag first flex-row"
          role="columnheader"
        >
          Date
        </div>
        <div
          class="text-tag flex-row"
          role="columnheader"
        >
          Description
        </div>
        <div
          class="text-tag flex-row"
          role="columnheader"
        >
          Project Type
        </div>
        <div
          class="text-tag flex-row"
          role="columnheader"
        >
          Key Topic
        </div>
        <div
          class="text-tag flex-row"
          role="columnheader"
        >
          Content Type
        </div>
      </div>

      <div
        v-for="(download, index) in filteredDownloadsSorted"
        :key="index"
        class="flex-table py-3 md:py-0"
        role="rowgroup"
      >
        <div class="column">
          <div
            class="flex-row"
            role="rowgroup"
          >
            <div
              class="flex-cell first"
              role="cell"
            >
              <span class="text-xs">
                {{
                  new Date(download.fields.date).toLocaleDateString("en-GB", {
                    month: "short",
                  })
                }}
              </span>
              <TextBadge class="ms-2">
                {{
                  download.metadata.tags
                    .filter((tag) => tag.sys.id.startsWith("year"))
                    .map((tag) =>
                      tag.sys.id.replace("year", "").replace(/([A-Z])/g, " $1"),
                    )[0]
                }}
              </TextBadge>
            </div>
            <div
              class="flex-cell"
              role="cell"
            >
              <div class="text-[20px] font-semibold lg:text-sm">
                <UiDownloadLink :download="download">
                  {{ download.fields.title }}
                </UiDownloadLink>
              </div>
            </div>
            <div
              class="flex-cell"
              role="cell"
            >
              <TextBadge class="!whitespace-normal">
                {{ download.fields.project?.fields.name }}
              </TextBadge>
            </div>
            <div
              class="flex-cell"
              role="cell"
            >
              <TextBadge
                v-if="download.fields.themeOrTopic"
                :colour="
                  isTypeTheme(download.fields.themeOrTopic)
                    ? download.fields.themeOrTopic.fields.colour
                    : isTypeTopic(download.fields.themeOrTopic)
                      ? download.fields.themeOrTopic.fields.theme?.fields.colour
                      : undefined
                "
                class="!whitespace-normal md:ms-3 lg:ms-0"
              >
                {{ download.fields.themeOrTopic.fields.title }}
              </TextBadge>
            </div>
          </div>
        </div>
        <div
          class="last flex-row"
          role="cell"
        >
          <div
            class="download-button rounded px-2 py-1 text-sm font-semibold max-lg:border max-lg:border-dark-blue-grey/10 max-lg:bg-dark-blue-grey/5 md:p-3 md:max-lg:w-full lg:p-0"
          >
            <UiDownloadLink
              :download="download"
              class-list="download-link flex flex-wrap items-center"
            >
              <NuxtIcon
                class="download-icon me-2 md:me-0 lg:me-2"
                :name="`fairr:${
                  download.fields.fileType.toLowerCase() !== 'url'
                    ? 'download'
                    : 'link-out'
                }`"
                size="26"
              />
              <div class="download-text">
                {{ download.fields.contentType }}
              </div>
              <div class="download-type ms-auto text-light-royal-blue md:mb-1 lg:mb-0 lg:ms-2">
                <NuxtIcon
                  :name="`fairr:${download.fields.fileType.toLowerCase()}`"
                  size="26"
                />
              </div>
              <template #locked>
                <div class="download-link flex-wraps flex items-center">
                  <NuxtIcon
                    v-if="download.fields.fileType"
                    class="download-icon me-2 md:me-0 lg:me-2"
                    :name="`fairr:${
                      download.fields.fileType.toLowerCase() !== 'url'
                        ? 'download'
                        : 'link-out'
                    }`"
                    size="26"
                  />
                  <div class="download-text">
                    {{ download.fields.contentType }}
                  </div>
                  <div class="download-type ms-auto text-light-royal-blue md:mb-1 lg:mb-0 lg:ms-2">
                    <NuxtIcon
                      name="fairr:locked"
                      size="26"
                    />
                  </div>
                </div>
              </template>
            </UiDownloadLink>
          </div>
        </div>
      </div>
      <ClientOnly>
        <vue-awesome-paginate
          v-if="paginationTotal > 12"
          v-model="currentPage"
          :total-items="paginationTotal"
          :items-per-page="perPage"
          :max-pages-shown="4"
          class="pagination-container"
          type="link"
          :link-url="`?page_${sys.id}=[page]${route.hash}`"
        >
          <template #prev-button>
            prev
          </template>
          <template #next-button>
            next
          </template>
        </vue-awesome-paginate>
      </ClientOnly>
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.download-search {
  input {
    width: 100%;
    border: none;

    &:focus {
      outline: none;
    }
  }
}

.download-button {
  .download-link {
    @apply md:max-lg:justify-center;
  }

  .download-icon {
    @apply order-1 md:max-lg:order-2;
  }

  .download-text {
    @apply order-2 md:max-lg:order-3;
  }

  .download-type {
    @apply order-3 md:max-lg:order-1 md:max-lg:flex-[0_0_100%] md:max-lg:text-center;
  }
}

.flex-table {
  &.header {
    .flex-row:nth-child(n + 3) {
      @apply max-lg:hidden;
    }
  }
}

.flex-row {
  @apply w-[calc(100%_/_2)] text-left;

  &:nth-child(1) {
    @apply lg:w-[12%];
  }

  &:nth-child(2) {
    @apply lg:w-[28%];
  }

  &:nth-child(3) {
    @apply lg:w-[24%];
  }

  &:nth-child(4) {
    @apply lg:w-[15%];
  }

  &:nth-child(5) {
    @apply lg:w-[21%];
  }

  &.last {
    @apply w-full max-sm:mt-2 md:w-[200px] md:border-l-[10px] md:border-l-white md:max-lg:flex md:max-lg:items-center lg:flex lg:h-[70px] lg:w-[20%] lg:items-center lg:justify-end;
  }
}

.flex-cell {
  &:nth-child(1) {
    @apply lg:w-[15%];
  }

  &:nth-child(2) {
    @apply lg:w-[35%];
  }

  &:nth-child(3) {
    @apply lg:w-[30%];
  }

  &:nth-child(4) {
    @apply lg:w-[20%];
  }

  &:nth-last-of-type(-n + 2) {
    @apply md:max-lg:w-auto;
  }
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  padding: 0;

  @apply md:w-[calc(100%-200px)] lg:w-[80%];

  .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;

    @apply md:max-lg:my-[10px];
  }

  .flex-cell + .flex-cell {
    @apply lg:border-l-[10px] lg:border-l-white;
  }
}
</style>
